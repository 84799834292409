<template>
    <div class="advimage">
       	<v-zoomer>
			<img :src="imgs" style="object-fit: contain; width: 100%; height: 100%;">
		</v-zoomer>
   </div>
</template>
<script>
export default {
	data () {
		return {
			imgs: this.$route.query.imageurl,
		}
	},
	mounted(){
		console.log(this.$route)
	},
	methods:{
	}
}
</script>
<style lang="scss">
.advimage{
	text-align: center;
	height: 100vh;
	background:#000;
	.vue-zoomer{
		width: 100%;
		height: 100%;
		margin: 0 auto;
		// margin-top:50%;
		.zoomer{
			width: 700px;
			margin: 0 auto;
			img{
				cursor: zoom-in;
			}
		}
	}
}
</style>